import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import CurrentContestTile from "components/CurrentContestTile";
import { graphql } from "gatsby";
import Pagination from "../components/Pagination";

const CurrentContestsPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const allContests = data.allWpContest.nodes;
   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="for-who-section">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row">
                  {allContests.length === 0 || undefined ? (
                     <div
                        style={{
                           fontSize: "1.3rem",
                           marginBottom: "30px",
                           textAlign: "center",
                        }}
                     >
                        Obecnie nie ma żadnych konkursów.
                     </div>
                  ) : (
                     allContests?.map((item, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-6" key={index}>
                           <CurrentContestTile
                              link={item.uri}
                              title={item.title}
                              category={item.contestsCategory?.nodes[0]?.name}
                              thumbnail={
                                 item.singleContestPage.mainImage?.sourceUrl
                              }
                              color={
                                 item.contestsCategory?.nodes[0]
                                    ?.contestsCategory.color
                              }
                              finished={
                                 item.singleContestPage.status === "Zakończony"
                              }
                           />
                        </div>
                     ))
                  )}
               </div>
               {allContests.length > 6 ? <Pagination {...pageContext} /> : ""}
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query ($limit: Int!, $skip: Int!, $filter: String, $isLocal: String) {
      allWpContest(
         limit: $limit
         skip: $skip
         filter: {
            singleContestPage: {
               status: { eq: $filter }
               isLocal: { eq: $isLocal }
            }
         }
         sort: { fields: date, order: DESC }
      ) {
         nodes {
            title
            uri
            id
            slug
            singleContestPage {
               isLocal
               status
               mainImage {
                  sourceUrl
               }
            }
            contestsCategory {
               nodes {
                  name
                  contestsCategory {
                     color
                  }
               }
            }
         }
      }
   }
`;

export default CurrentContestsPage;
