import "./current-contest-tile.scss";

import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const CurrentContestTile = ({
  category,
  title,
  link,
  thumbnail,
  color,
  finished,
}) => {
  return (
    <Link to={link}>
      <div
        className={classNames(`current-contest-tile`, {
          [`current-contest-tile--finished`]: finished,
        })}
      >
        <div
          className="current-contest-tile__category"
          style={{ backgroundColor: color }}
        >
          {category}
        </div>
        <div
          className="current-contest-tile__thumbnail"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <div
          className="current-contest-tile__title"
          style={{ backgroundColor: color }}
        >
          {title}
        </div>
      </div>
    </Link>
  );
};

export default CurrentContestTile;
